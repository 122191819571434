import React from "react";
import classnames from "classnames";
import {Link, useRouteMatch} from "react-router-dom";
import style from "./Nav.scss";

const Nav = ({data = []}) => {
  const {url} = useRouteMatch();

  return (
    <ul className={classnames("nav nav-pills justify-content-center", style.nav)}>
      {data.map(({text, to}) => (
        <li className="nav-item" key={text}>
          <Link
            replace
            className={classnames("nav-link", {[style.active]: url === to || url === `${to}/`})}
            to={to}
          >
            {text}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Nav;
