import React, {useEffect} from "react";
import classnames from "classnames";
import {useHistory, useRouteMatch} from "react-router";
import {introductionData} from "src/constants/introduction";
import Nav from "src/components/Nav";
import video from "assets/video.mp4";
import style from "./Introduction.scss";
import {introductionNavData, introductionParams, mainRoutes} from "../constants/routes";
import useScrollTop from "../hooks/useScrollTop";

const Introduction = () => {
  const {params} = useRouteMatch();
  const history = useHistory();
  const {title, subTitle, items} = introductionData[params.project || ""] || {};

  useEffect(() => {
    !Object.values(introductionParams).includes(params.project) &&
      history.replace(mainRoutes.Introduction);
  }, []);

  useScrollTop();

  return (
    <>
      <header className={classnames("text-center", style.header)}>
        <video autoPlay loop muted playsInline className={style.video}>
          <source src={video} type="video/mp4" />
        </video>
        <div className={style.viewport_header}>
          <div
            className={classnames(style.main_title, "text-center")}
            style={{padding: "30px", color: "#fff"}}
          >
            <div className={classnames("logo_font", style.title)}>
              KEC
              <h3 className={style.content}>프리콘의 名家</h3>
            </div>
          </div>
        </div>
        <div className={style.project_name}>선경이엔씨 - 시화MTV 물류센터</div>
      </header>
      <div className="main">
        <Nav data={introductionNavData} />
        <div className="container">
          <div className="text-center">
            <h2>{title}</h2>
            {subTitle && <div>{subTitle}</div>}
          </div>
          {(items || []).map(({title: historyTitle, contents}) => (
            <div className={style.timeline} key={historyTitle}>
              <div className={style.icon} />
              <div key={title}>
                <div className={style.title_content}>
                  <span className={style.title}>{historyTitle}</span>
                </div>
                <ul className={style.content}>
                  {contents.map(v => (
                    <li key={v}>{v}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Introduction;
