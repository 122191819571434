import React, {useState} from "react";
import {useLocation} from "react-router";
import classnames from "classnames";
import {NavHashLink} from "react-router-hash-link";
import {log} from "../utils/log";

const Dropdown = ({data = [], id = "", pathname = "", title = "", onClose = () => {}}) => {
  const [isShow, setIsShow] = useState(false);
  const handleFocus = () => setIsShow(true);
  const handleBlur = () => setIsShow(false);
  const location = useLocation();

  return (
    <div
      onMouseOver={handleFocus}
      onFocus={handleFocus}
      onMouseLeave={handleBlur}
      onBlur={handleBlur}
    >
      <button
        className={classnames("btn nav-link dropdown-toggle", {
          active: location.pathname === pathname || data.some(v => v.to === location.pathname),
        })}
        id={id}
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={isShow}
      >
        {title}
      </button>
      <div className={classnames("dropdown-menu", {show: isShow})} aria-labelledby={id}>
        {data.map(({to, text, divided}) => (
          <React.Fragment key={text}>
            {divided && <div className="dropdown-divider" />}
            <NavHashLink
              smooth
              className="nav-link"
              to={typeof to === "object" ? {...to, ...(pathname && {pathname})} : to}
              activeClassName="active"
              onClick={() => {
                log({to, text});
                onClose && onClose();
              }}
            >
              {text}
            </NavHashLink>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
