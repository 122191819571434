import React from "react";
import classnames from "classnames";
import style from "./Footer.scss";

const data = [
  [{title: "대표자", contents: "이양호 / 이인호"}],
  [{title: "사업자등록번호", contents: "303-81-34107"}],
  [
    {title: "TEL", contents: "043-856-0246~7"},
    {title: "FAX", contents: "043-856-0248"},
  ],
  [{title: "주소", contents: "충북 충주시 가주농공 1길 17(풍동)"}],
];
const companyName = "(주) 케이이씨";

const Footer = () => (
  <footer className={classnames("text-white", style.footer)}>
    <div className="container p-4">
      <div className="row">
        <div className={classnames("col-lg-3 offset-lg-3 text-center", style.logo)}>
          <h3>{companyName}</h3>
        </div>
        <div className="col-lg-6">
          {data.map((items, i) => (
            <div className={style.items} key={String(i)}>
              {items.map(({title, contents}) => (
                <div className={style.item} key={title}>
                  <span className={style.title}>{title}</span>
                  <span className={style.contents}>{contents}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className={classnames("text-center p-3", style.copyright)}>
      © 2023 Copyright&nbsp;
      <a className="text-white" href="/">
        {companyName}
      </a>
    </div>
  </footer>
);

export default Footer;
