import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import classnames from "classnames";
import {Link} from "react-router-dom";
import {NavHashLink} from "react-router-hash-link";
import {homeSection, introductionNavData, mainRoutes} from "src/constants/routes";
import Dropdown from "src/components/Dropdown";
import {log} from "../utils/log";
import {isMobile} from "../constants/env";

const navId = "navbar";

const getOverflow = () => (window.innerWidth > 768 ? "visible" : "hidden");

const data = [
  {
    title: "회사 소개",
    id: "nav_home",
    pathname: mainRoutes.Home,
    dropdowns: [
      {text: "회사 소개", to: {hash: homeSection.Project}},
      {text: "사업 실적", to: {hash: homeSection.Business}},
      {text: "공장 소개", to: {hash: homeSection.Factory}},
    ],
  },
  {
    title: "사업 영역",
    id: "nav_introduction",
    dropdowns: introductionNavData,
  },
  {title: "견적문의", id: "nav_contact", to: mainRoutes.Contact},
];
const TopNav = () => {
  const [isShow, setIsShow] = useState(false);
  const [transition, setTransition] = useState(false);
  const [overflow, setOverflow] = useState();
  const onClose = useCallback(
    e => {
      isShow && setTransition(true);
    },
    [isShow],
  );
  // eslint-disable-next-line no-nested-ternary
  // IE - maxHeight = 0 일 경우 가운데 정렬이 안됨
  // 애니메이션 적용될때만 maxHeight 적용
  // eslint-disable-next-line no-nested-ternary
  const maxHeight = useMemo(() => (transition ? (isShow ? "150px" : 0) : undefined), [
    isShow,
    transition,
  ]);
  const $container = useRef(null);
  const isShowRef = useRef(isShow);

  useEffect(() => {
    isShowRef.current = isShow;
  }, [isShow]);

  useEffect(() => {
    transition && setIsShow(!isShow);
  }, [transition]);

  useEffect(() => {
    setOverflow(getOverflow());

    if (isMobile) {
      const handleClickOutside = e => {
        if (isShowRef.current && !$container.current.contains(e.target)) {
          setTransition(true);
        }
      };

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }

    const handleResize = () => {
      setOverflow(getOverflow());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="container" ref={$container}>
      <nav className="navbar fixed-top navbar-expand-md navbar-light bg-light">
        <Link className="navbar-brand logo_font" to="/" onClick={() => onClose()}>
          KEC
        </Link>
        <button
          className={classnames("navbar-toggler", {collapsed: isShow})}
          type="button"
          data-toggle="collapse"
          data-target={`#${navId}`}
          aria-controls={navId}
          aria-expanded={isShow}
          onClick={() => {
            setTransition(true);
          }}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className={classnames("navbar-collapse collapse", {show: transition || isShow})}
          id={navId}
          style={{
            transition: "max-height 300ms ease-in-out",
            maxHeight,
            overflow,
          }}
          onTransitionEnd={() => setTransition(false)}
        >
          <ul className="navbar-nav mr-auto">
            {data.map(({title, to, dropdowns = [], pathname, id}) => (
              <li className={classnames("nav-item", {dropdown: dropdowns.length > 0})} key={title}>
                {to && (
                  <NavHashLink
                    smooth
                    className="nav-link"
                    to={to}
                    activeClassName="active"
                    onClick={() => {
                      log({to, title});
                      dropdowns.length === 0 && onClose();
                    }}
                  >
                    {title}
                  </NavHashLink>
                )}
                {dropdowns.length > 0 && (
                  <Dropdown
                    {...{
                      id,
                      pathname,
                      data: dropdowns,
                      title,
                      onClose,
                    }}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default TopNav;
