let analytics;

export const initLog = async () => {
  const {firebase} = await import("@firebase/app");

  await import("@firebase/analytics");

  const firebaseConfig = {
    apiKey: "AIzaSyDvgmwiK2ibmemJr2G9fCZ1uA4Sg0Ar-9w",
    authDomain: "email-27465.firebaseapp.com",
    projectId: "email-27465",
    storageBucket: "email-27465.appspot.com",
    messagingSenderId: "1082299976237",
    appId: "1:1082299976237:web:c38accf9eb5300384cc6a4",
    measurementId: "G-RV1KFWV4JG",
  };

  firebase.initializeApp(firebaseConfig);
  analytics = firebase.analytics();
};

export const log = options => {
  analytics && analytics.logEvent("select_content", {items: [options]});
};
