import React from "react";
import {Link} from "react-router-dom";
import classnames from "classnames";
import building from "assets/building.jpg";
import etc from "assets/etc.jpg";
import parking from "assets/parking.jpg";
import {introductionRoutes, homeSection} from "src/constants/routes";
import style from "./HomeBusiness.scss";
import Title from "./Title";

const data = [
  {
    imageUrl: building,
    title: "대형건축물",
    path: introductionRoutes.Building,
    subTitle: "물류창고 냉동창고 쇼핑몰",
  },
  {
    imageUrl: parking,
    title: "지하주차장",
    path: introductionRoutes.Parking,
    subTitle: "아파트 오피스텔",
    desc: "",
  },
  {
    imageUrl: etc,
    title: "기타",
    path: introductionRoutes.Etc,
    subTitle: "옥상 옥탑조형물 발코니",
    desc: "",
  },
];

const HomeBusiness = () => (
  <div className={classnames("container", style.container)} id={homeSection.Business}>
    <Title title="사업 실적" />
    <div className={classnames("row justify-content-md-center", style.card_container)}>
      {data.map(({title, subTitle, path, imageUrl}) => (
        <div className={style.card} key={title}>
          <Link to={path}>
            <img
              className={style.img}
              alt={`${title} 사진`}
              src={
                imageUrl ||
                "https://img.freepik.com/free-photo/_1127-2397.jpg?size=626&ext=jpg&ga=GA1.2.218822329.1595808000"
              }
            />
            <div className={style.contents}>
              <h5>{title}</h5>
              <div>{subTitle}</div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  </div>
);

export default HomeBusiness;
