import React, {useEffect, useRef, useState} from "react";
import {homeSection} from "src/constants/routes";
import m from "assets/factory/mungyeong.jpg";
import g from "assets/factory/geumwang.jpg";
import y from "assets/factory/yeongwol.jpeg";
import da from "assets/factory/damyang.jpeg";
import j from "assets/factory/jincheon.jpeg";
import style from "./HomeFactory.scss";
import Title from "./Title";
import {isMobile} from "../constants/env";
import loadScript from "../utils/loadScript";

const getData = () => [
  {
    name: "금왕 공장",
    imgUrl: g,
    area: "56,640",
    capacity: "620",
    address: "충북 음성군 금왕읍 봉곡리 96-2",
    tel: "043-883-0246",
    position: new window.naver.maps.LatLng(36.9605803, 127.5788822),
  },
  {
    name: "문경 공장",
    imgUrl: m,
    area: "28,055",
    capacity: "400",
    address: "경북 문경시 신기공단 1길 62",
    tel: "054-554-024",
    position: new window.naver.maps.LatLng(36.6357094, 128.1759481),
  },
  {
    name: "영월 공장",
    imgUrl: y,
    area: "18,700",
    capacity: "200",
    address: "강원 영월군 주천면 금마리 380",
    tel: "033-375-0246",
    position: new window.naver.maps.LatLng(37.242399, 128.2659222),
  },
  {
    name: "진천 공장",
    imgUrl: j,
    area: "14,000",
    capacity: "100",
    address: "충북 진천군 문백면 도하리 산 25-3",
    tel: "043-753-8030",
    position: new window.naver.maps.LatLng(36.77858, 127.4481),
  },
  {
    name: "담양 공장",
    imgUrl: da,
    area: "12,500",
    capacity: "100",
    address: "전남 담양군 금성면 금성산성길 71",
    tel: "061-882-7246",
    position: new window.naver.maps.LatLng(35.3542376, 127.0274636),
  },
];

const cardData = [
  {
    title: "대지면적",
    unit: "평",
    key: "area",
  },
  {
    title: "일일생산량",
    unit: "m³",
    key: "capacity",
  },
];

const HomeFactory = () => {
  const $map = useRef();
  const map = useRef();
  const [data, setData] = useState([]);

  useEffect(() => {
    loadScript(
      "https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=baiwptkb4j&&submodules=geocoder",
    ).then(() => {
      const d = getData();

      setData(d);
      map.current = new window.naver.maps.Map("map", {
        zoomControl: true,
        scrollWheel: false,
        zoomControlOptions: {
          style: window.naver.maps.ZoomControlStyle.SMALL,
          position: window.naver.maps.Position.TOP_RIGHT,
        },
        center: new window.naver.maps.LatLng(36.6357094, 128.1759481),
        zoom: 7,
        minZoom: 5,
      });

      d.forEach(({position, name}) => {
        const marker = new window.naver.maps.Marker({
          title: name,
          position,
          map: map.current,
          icon: {
            content: `<div><span class=${style.marker_title}>${name}</span><div class=${style.marker}></div></div>`,
            size: new window.naver.maps.Size(22, 35),
            anchor: new window.naver.maps.Point(11, 35),
          },
        });

        window.naver.maps.Event.addListener(marker, "click", () => {
          let url = "";

          if (isMobile) {
            url = `https://m.map.naver.com/directions/?ename=${name}&ex=${position.x}&ey=${position.y}`;
          } else {
            const epsg3857 = window.naver.maps.TransCoord.fromLatLngToEPSG3857(position);

            url = `https://map.naver.com/v5/directions/-/${epsg3857.x},${
              epsg3857.y
            },${encodeURIComponent(name)},,,/-/car`;
          }
          window.open(url);
        });
      });
    });
  }, []);

  return (
    <div className={style.container} id={homeSection.Factory}>
      <Title title="공장 소개" />
      <div ref={$map} id="map" style={{width: "100%", height: "400px"}} />
      <ul className={style.features}>
        {data.map(({name, imgUrl, address, tel, position, ...etc}) => (
          <li key={name}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events*/}
            <div
              className={style.img_container}
              onClick={() => {
                map.current.setZoom(13, true);
                map.current.panTo(position);
              }}
            >
              {imgUrl && (
                <>
                  <img src={imgUrl} alt={`${name} 사진`} />
                  <span className={style.img_title}>{name}</span>
                </>
              )}
            </div>
            <div className={style.info}>
              <div className={style.card_container}>
                {cardData.map(({title, unit, key}) => (
                  <div className={style.card} key={key}>
                    <div className={style.title}>{title}</div>
                    <div className={style.content}>
                      {etc[key]}
                      {unit && <span className={style.unit}>{unit}</span>}
                    </div>
                  </div>
                ))}
              </div>
              <div>{address}</div>
              <a href={`tel:${tel}`}>{tel}</a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HomeFactory;
