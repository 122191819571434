export default function loadScript(src) {
  return new Promise(resolve => {
    const script = document.createElement("script");

    script.src = src;
    script.type = "text/javascript";
    script.async = true;
    script.onload = resolve;
    document.body.appendChild(script);
  });
}
