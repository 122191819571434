import React, {useRef, useState} from "react";
import classnames from "classnames";
import Flicking from "@egjs/react-flicking";
import {Fade, AutoPlay} from "@egjs/flicking-plugins";
import main1 from "assets/main1.jpg";
import main2 from "assets/main2.jpg";
import main3 from "assets/main3.jpg";
import Business from "src/components/HomeBusiness";
import HomeFactory from "src/components/HomeFactory";
import HomeProject from "src/components/HomeProject";
import style from "./Home.scss";
import useScrollTop from "../hooks/useScrollTop";

const mainImages = [
  {imageUrl: main1, projectName: "현대건설 - 김포 고촌 물류센터"},
  {imageUrl: main2, projectName: "포스코건설 - 남안산 정왕동 물류센터"},
  {imageUrl: main3, projectName: "포스코건설 - 인천 송도M1 지하주차장"},
];

const plugins = [new Fade(), new AutoPlay(5000, "NEXT")];
const Home = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const $flicking = useRef(null);
  const [isShowFlickingButton, setIsShowFlickingButton] = useState(false);

  useScrollTop();

  return (
    <>
      <div className="splash">
        <div className={style.header}>
          {/* eslint-disable-next-line */}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10,
              width: "100%",
              height: "100%",
            }}
            onMouseOver={() => setIsShowFlickingButton(true)}
            onMouseLeave={() => setIsShowFlickingButton(false)}
          >
            {isShowFlickingButton && (
              <>
                {/* eslint-disable-next-line */}
                <a
                  role="button"
                  className="carousel-control-prev"
                  onClick={() => $flicking.current.prev()}
                >
                  <span className="carousel-control-prev-icon" aria-hidden />
                  <span className="sr-only">Previous</span>
                </a>
                {/* eslint-disable-next-line */}
                <a
                  className="carousel-control-next"
                  role="button"
                  onClick={() => $flicking.current.prev()}
                >
                  <span className="carousel-control-next-icon" aria-hidden />
                  <span className="sr-only">Next</span>
                </a>
              </>
            )}
          </div>
          <Flicking
            style={{color: "#fff", width: "100%"}}
            plugins={plugins}
            gap={10}
            circular
            autoResize
            ref={$flicking}
            zIndex={0}
            onMoveEnd={e => setSelectedIndex(e.index)}
          >
            {mainImages.map(({imageUrl, projectName}, i) => (
              <div key={projectName} style={{width: "100%", height: "60vh", minHeight: "300px"}}>
                <div className={style.project_name}>
                  <span>{projectName}</span>
                </div>
                <div className={style.img} style={{backgroundImage: `url("${imageUrl}")`}} />
              </div>
            ))}
          </Flicking>
          <ul className={style.indicators}>
            {mainImages.map((v, i) => (
              <li key={v.projectName} className={classnames({[style.active]: i === selectedIndex})}>
                {i}
              </li>
            ))}
          </ul>
          <div
            className={classnames(style.main_title, "text-center")}
            style={{padding: "30px", color: "#fff"}}
          >
            <div className={classnames(style.title, "logo_font")}>KEC</div>
            <h3 className={style.content}>프리콘의 名家</h3>
          </div>
        </div>
      </div>
      <div className="main">
        <HomeProject />
        <Business />
        <HomeFactory />
      </div>
    </>
  );
};

export default Home;
