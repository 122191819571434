import React from "react";
import {render} from "react-dom";
import App from "src/App.jsx";
import "whatwg-fetch";
import "@babel/polyfill";
import "react-app-polyfill/ie9";

if (!window.MutationObserver) {
  window.MutationObserver = require("mutation-observer");
}

const $app = document.getElementById("app");

render(<App suppressHydrationWarning />, $app);
