import React, {useState} from "react";
import {useRouteMatch} from "react-router";
import classnames from "classnames";
import {homeSection} from "src/constants/routes";
import Title from "src/components/Title";
import style from "./Contact.scss";
import {mainRoutes} from "../constants/routes";
import useScrollTop from "../hooks/useScrollTop";

const rows = [
  [
    {
      title: "Email",
      key: "email",
      placeholder: "email@example.com",
      check: v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v),
      invalidMessage: "이메일을 확인해주세요",
    },
    {
      title: "이름",
      key: "name",
    },
  ],
  [
    {
      key: "subject",
      placeholder: "제목",
    },
  ],
];

// eslint-disable-next-line no-unused-vars
const testEmailUrl = "http://localhost:5001/email-27465/us-central1/sendMail";
const firebaseEmailUrl = "https://us-central1-email-27465.cloudfunctions.net/sendMail";

const pageData = {
  [mainRoutes.Contact]: {
    title: "견적문의",
  },
  [mainRoutes.Apply]: {
    title: "입사지원",
  },
};

const Contact = () => {
  useScrollTop();

  const [formData, setFormData] = useState({});
  const [invalid, setIsInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const {path} = useRouteMatch();

  const handleChange = (e, key) => {
    const v = e.target.value;

    setFormData(prev => ({...prev, [key]: v}));
  };
  const handleSubmit = () => {
    const {name, email, subject, contents} = formData;
    const isInvalid = rows.some(array =>
      array.some(({key, check}) => {
        const v = formData[key];

        return !(check ? check(v) : v);
        // eslint-disable-next-line comma-dangle
      }),
    );

    setIsInvalid(isInvalid);

    if (isInvalid) {
      return;
    }
    setLoading(true);

    const data = {
      from: `${name} <${email}>`,
      email,
      subject,
      html: contents,
      to: "welcomekec@hanmail.net",
    };

    fetch(firebaseEmailUrl, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then(response => {
      setLoading(false);

      if (response.ok) {
        window.alert("메일을 성공적으로 보냈습니다\n검토 후 답변드리겠습니다\n감사합니다");
      } else {
        window.alert("메일 보내기에 실패하였습니다\n잠시 후에 다시 시도해주세요");
      }
    });
  };

  return (
    <div className={classnames("container", style.container)} id={homeSection.Contact}>
      <Title title={pageData[path].title} />
      <div className={style.form}>
        {rows.map((data, index) => (
          <div className="form-group row" key={String(index)}>
            {data.map(({title, key, placeholder, check, invalidMessage}) => {
              const v = formData[key];
              const isInvalid = invalid && (check ? !check(v) : !v);

              return (
                <div className={`col-md-${12 / data.length}`} key={key}>
                  {title && <label htmlFor={key}>{title}</label>}
                  <input
                    type="text"
                    className={classnames("form-control", {"is-invalid": isInvalid})}
                    id={key}
                    value={formData[key] || ""}
                    onChange={e => handleChange(e, key)}
                    placeholder={placeholder}
                  />
                  <div className="invalid-feedback">{invalidMessage || "필수 입력입니다"}</div>
                </div>
              );
            })}
          </div>
        ))}
        <textarea
          className={classnames("form-control", {"is-invalid": invalid && !formData.contents})}
          value={formData.contents || ""}
          onChange={e => handleChange(e, "contents")}
        />
        <div className="invalid-feedback">필수 입력입니다</div>
        <button
          type="button"
          className="btn btn-secondary col-12"
          style={{marginTop: "10px"}}
          onClick={handleSubmit}
        >
          보내기
          {loading && (
            <>
              &nbsp;&nbsp;
              <span className="spinner-border spinner-border-sm" role="status" />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default Contact;
