import {introductionParams} from "./routes";

export const introductionData = {
  [introductionParams.Building]: {
    title: "대형건축물",
    subTitle: "물류창고, 냉동창고, 쇼핑몰",
    items: [
      {
        title: 2020,
        contents: [
          "기흥 – 이천 고백리 물류 창고",
          "대림건설 – 오산 풍농 물류센터",
          "덕성종합건설 – 삼우 F&G 광주평동물류센터",
          "이테크건설 – 쿠팡광주 COLD PROJECT",
          "참존건설 – 화성동탄 물류센터",
          "참존건설 – 한섬 이천패션물류센터 D7",
          "현대건설 – 창원로지스틱스파크 물류센터(2차)",
          "현대엔지니어링 – 오산역 복합물류센터",
        ],
      },
      {
        title: "2019",
        contents: [
          "롯데건설 - 롯데 중부권 메가허브터미널",
          "롯데건설 – 롯데 양산 통합 물류센터",
          "선경이엔씨 – 로지스밸리 이천 물류센터",
          "선경이엔씨 – 로지스밸리 하나로 안성 물류센터",
          "선경이엔씨 - 위비스 안성 물류센터",
          "선경이엔씨 – SPL 화성 정남 물류센터",
          "포스코건설 – 마스턴 안산물류센터",
          "현대건설 – 창원로지스틱스파크 물류센터",
        ],
      },
      {
        title: "2018",
        contents: [
          "경동건설 – 이케아 동부산물류창고",
          "동원건설산업 – 삼양사 인천2공장 증축공사",
          "덕성종합건설 – 김해 삼우 F&G 냉동창고",
          "벽산엔지니어링 – 경기 광주 BGF 물류창고",
          "선경이엔씨 – 도미노피자 용인허브센터 물류창고",
          "이테크건설 – 용인 백봉리 물류창고",
          "참존건설 – 현대 리바트 용인공장 증축공사",
          "파인건설 – 안성 산하리 물류창고",
          "현대건설 – 김포 고촌 물류창고",
        ],
      },
      {
        title: "2017",
        contents: [
          "기흥건설 – 씬시어 로지텍 물류창고",
          "롯데건설 – 용인 기흥 프리미엄 아울렛",
          "선경이엔씨 – 휴매드 시화 MTV 물류창고 제작 납품",
          "선경이엔씨 – 충주 팅크웨어 물류창고 제작 납품",
        ],
      },
      {
        title: "2016",
        contents: [
          "롯데건설 – 증평 신선센터 물류창고",
          "한성PC건설 – CJ물류창고(SLAB 납품)",
          "이랜드건설 – 천안통합물류 Z3 물류창고",
          "선경이엔씨 – 양지 SLC 물류창고 제작 납품",
          "선경이엔씨 – 정수 유통 물류창고 제작 납품",
          "선경이엔씨 – 오뚜기 칠곡 물류창고 제작 납품",
        ],
      },
      {
        title: "2015",
        contents: [
          "참존건설 – 현대 리바트 용인 물류창고",
          "현대산업개발 – 시흥 길산 물류창고(SLAB 납품)",
          "선경이엔씨 – 이천 DSL 물류창고",
        ],
      },
      {
        title: "2014",
        contents: [
          "서브원 – 해태음료 천안 물류창고",
          "선경이엔씨 – 아이올리 용인 물류창고 제작 납품",
          "선경이엔씨 – 덕평 휴매드 물류창고 제작 납품",
          "선경이엔씨 – 수인터네셔널 물류창고 제작 납품",
          "선경이엔씨 – 슈페리얼 물류창고 제작 납품",
        ],
      },
    ],
  },
  [introductionParams.Parking]: {
    title: "지하주차장",
    subTitle: "아파트, 오피스텔",
    items: [
      {
        title: 2020,
        contents: [
          "극동건설 – 세종 4-1 지하주차장",
          "롯데건설 – 둔촌 주공 APT 지하주차장",
          "롯데건설 – 속초시 동명동 지하주차장",
          "롯데건설 – 의정부 가능1구역 지하주차장",
          "롯데건설 – 자양1구역 지하주차장",
          "롯데건설 – 화성반월3단지 지하주차장",
          "서희건설 – 대구 경산중방 지하주차장",
          "이랜드건설 – 안성 공도센트럴파크 지하주차장",
          "제일건설 – 원주혁신도시 지하주차장",
          "제일건설 – 충주호암지구 지하주차장",
          "제일건설 – 평택국제화지구 41블럭 지하주차장",
          "제일건설 – 평택국제화지구 42블럭 지하주차장",
          "제일건설 – 하남강일지구 지하주차장",
          "제일건설 – 용인영덕지구 지하주차장",
          "제일건설 – 광주남구 도시첨단 D-2BL 지하주차장",
          "포스코건설 – 평택 지제 더샵 지하주차장",
          "포스코건설 – 신길3구역 더샵 지하주차장",
          "GS건설 – 개포 프레지던스 자이 지하주차장",
        ],
      },
      {
        title: "2019",
        contents: [
          "롯데건설 – 화성 병점 역세권 공동주택 지하주차장",
          "롯데건설 – 거여동 2-1 구역 지하주차장",
          "롯데건설 – 의정부 중앙2구역 지하주차장",
          "서희건설 – 화성시청역 1, 2, 3 블록 지하주차장",
          "코오롱글로벌 – 부개역 코오롱하늘채 지하주차장",
          "코오롱글로벌 – 수원 곡반정동 명당 1, 2단지 지하주차장",
          "포스코건설 –  판교 더샵  퍼스트파크 지하주차장",
          "포스코건설 – 십정 2구역 지하주차장",
          "포스코건설 – 남양주 부평2구역 지하주차장",
          "포스코건설 – 군산 디오션시티 A4블록 지하주차장",
          "포스코건설 – 인천 주안4구역 지하주차장",
          "GS건설 – 남양주 다산 지금 A1 지하주차장",
          "GS건설 – 남양주 다산 진건 B3 지하주차장",
          "GS건설 – 과천 주공 6단지 지하주차장",
          "GS건설 – 의정부 중앙2구역 지하주차장",
        ],
      },
      {
        title: "2018",
        contents: [
          "롯데건설 – 김포 신곡 아파트 지하주차장",
          "롯데건설 – 김포 신곡 아파트 지하주차장(2차)",
          "롯데건설 – 창원 회원동 아파트 지하주차장 ",
          "롯데건설 – 부산 연산 6구역 지하주차장",
          "신동아건설 – 김포 신곡 아파트 지하주차장",
          "제일건설 – 시흥 장현 B4 지하주차장",
          "제일건설 – 시흥 장현 B5 지하주차장",
          "제일건설 – 세종 2-4 생활권 지하주차장",
          "제일건설 - 의왕 백운 A4현장 지하주차장 ",
          "태영건설 – 세종 6-4 생활권 지하주차장",
          "코오롱글로벌 – 인천 부개 인우 지하주차장",
          "포스코건설 – 송도 M1 지하주차장",
          "현대엔지니어링 – 평택 영신 지역주택조합 지하주차장",
          "GS건설 – 고덕 6단지 지하주차장 제작 납품",
        ],
      },
      {
        title: "2017",
        contents: [
          "롯데건설 – 서울 고덕주공 7단지 재건축 지하주차장",
          "롯데건설 – 세종 4-1 생활권 지하주차장",
          "신동아건설 – 세종 4-1 생활권 지하주차장 ",
          "제일건설 – 평택 고덕 신도시 A17BL 지하주차장",
        ],
      },
      {
        title: "2016",
        contents: [
          "까뮤이앤씨 – 현대건설 안산 지하주차장(SLAB 납품)",
          "롯데건설 – 안산 군자 지하주차장",
          "롯데건설 – 화성 반월동 지하주차장",
          "롯데건설 – 창원 합성 1구역 지하주차장",
          "롯데건설 – 원주 기업도시 10BL 지하주차장",
          "롯데건설 – 의정부 직동 공원 1,2BL 지하주차장",
          "세안 – 우미건설 의정부 민락 지하주차장(SLAB 납품)",
          "제일건설 – 하남미사 A33 지하주차장",
          "태명실업 – 롯데건설 원주 9BL 주차장(SLAB 납품)",
        ],
      },
      {
        title: "2015",
        contents: [
          "롯데건설 – 부산 대연 2차 지하주차장",
          "롯데건설 – 파주 27-1 지하주차장",
          "롯데건설 – 당산동 2차 지하주차장",
          "롯데건설 – 아산 모종 2차 지하주차장",
          "현대산업개발 – 한강 신도시 지하주차장(SLAB 납품)",
          "현대산업개발 – 인천 가정동 주차장(GIRDER 납품)",
        ],
      },
    ],
  },
  [introductionParams.Etc]: {
    title: "기타",
    subTitle: "옥상, 옥탑조형물, 발코니",
    items: [
      {
        title: 2020,
        contents: [
          "대우건설 - 사당3구역 푸르지오 옥상 조형물",
          "대우건설 - 영종하늘도시 A-12BL 옥상 조형물",
          "대우건설 – 춘천센트럴파크 푸르지오 옥상 조형물",
          "대우건설 – 남양주 다산지금 A-4BL 옥상 조형물",
          "대우건설 – 검단센트럴푸르지오 AB16 옥상 조형물",
          "대우건설 – 파주운정A14BL 옥상 조형물",
          "대우건설 – 대전 중촌 푸르지오 옥상 조형물",
          "대우건설 – 광양 푸르지오더퍼스트 옥상조형물 ",
          "대우건설 – 여주 푸르지오 옥상조형물",
          "대우조선해양 – 고덕강일1단지 옥상 조형물 ",
          "두산건설 - 고덕강일 4단지 계단실 PC공사",
          "롯데건설- 길음 1구역 옥탑, 헬리포트 PC공사",
          "신영건설- 이문휘경지웰 옥상조형물",
          "우룡건설- 고덕강일공공주택지구 14단지 옥상조형물",
          "코오롱글로벌- 대구남산 4-4지구 재개발 옥상조형물",
          "포스코건설 – 분당 더샵 파크리버 옥상 조형물",
          "현대건설 – 연세중입자암치료센터 장비반입구 PC공사",
          "현대엔지니어링- 여수 죽림 옥상 조형물",
          "혜림건설- 신가동모아엘가 옥상 조형물",
        ],
      },
      {
        title: "2019",
        contents: [
          "롯데건설- 부산 연산 6구역 옥상 조형물",
          "롯데건설- 창원 회원동 옥상 조형물",
          "롯데건설- 문래 기업형 임대주택 옥상 조형물",
          "롯데건설- 상도역세권 공동주택 옥상 조형물",
          "대우건설- 포항 장성 푸르지오 옥상 조형물",
          "대우건설- 의왕장안파크 2차 푸르지오 옥상 조형물",
          "대우건설- 천안성성2지구 옥상 조형물",
          "대우건설- 무안오룡에듀포레 푸르지오 옥상 조형물",
          "대우건설- 청주 새적굴근린공원 공동주택 옥상조형물",
          "포스코건설- 송도 A1블럭 옥상 조형물",
          "동원개발- 해운대 비스타 로얄듀크 옥상 조형물",
          "동원개발- 삼송2차 원흥역 동원 로얄듀크 옥상 조형물",
          "혜림건설- 광천 모아엘가 옥상조형물",
          "GS건설 –서초그랑자이 발코니 현대산업개발 – 인천 가정동 주차장(GIRDER 납품)",
        ],
      },
      {
        title: "2018",
        contents: [
          "대우건설 – 인천논현 푸르지오 옥상 조형물",
          "대우건설 – 지축역 센트럴 푸르지오 옥상 조형물",
          "대우건설 – 시흥 센트럴 푸르지오 옥상 조형물",
          "대우건설 – 경산 펜타힐즈 푸르지오 옥상 조형물",
          "대우건설 – 안산 초지역 메트로단지 옥상 조형물",
          "대우건설 – 용인 수지 옥상 조형물",
          "대우건설 – 안산 초지연립상가 파크단지 옥상 조형물",
          "대우건설- 상계역 센트럴 푸르지오 옥상 조형물",
          "대우건설- 밀양강 푸르지오 옥상 조형물",
          "롯데건설 – 고덕 7단지 옥상 조형물",
          "롯데건설 – 용인 성복동 주상복합/오피스텔 옥탑 조형물",
          "월드컵 – 부산 사상구남역 동원로얄듀크 옥탑 조형물",
          "혜림건설 – 백조2차 아파트 재건축 옥상 조형물",
          "GS건설- 속초 자이 APT 발코니 공사",
        ],
      },
      {
        title: "2017",
        contents: [
          "대우건설 – 평택 소사벌 푸르지오 옥상 조형물",
          "대우건설 – 평택 비전 지웰 푸르지오 옥상 조형물",
          "대우건설 – 의왕 장안지구 파크 푸르지오 옥상 조형물",
          "대우건설 – 청주 테크노폴리스 푸르지오 옥상 조형물",
          "대우건설 – 안산파크 푸르지오 옥상 조형물",
          "대우건설 – 천안 불당 푸르지오 1, 2단지 옥상 조형물",
          "대우건설 – 화성 동탄 2지구 5, 6BL 옥상 조형물",
          "대우건설 – 거창 푸르지오 옥상 조형물",
          "대우건설 – 삼송 원흥역 푸르지오 옥상 조형물",
          "대우건설 – 안산 센트럴 푸르지오 옥상 조형물",
          "대우건설 – 일산 에듀포레 푸르지오 옥상 조형물",
          "대우건설 – 군산 디오션시티 푸르지오 옥상 조형물",
          "대우건설 – 평택 비전 2차 푸르지오 옥상 조형물",
          "대우건설 – 운정 신도시 센트럴 푸르지오 옥상 조형물",
          "대우건설 – 부산 대신 푸르지오 옥상 조형물",
          "롯데건설 – 대연 2구역 아파트 사업 옥상 조형물",
          "롯데건설 – 연산 4구역 아파트 사업 옥상 조형물",
          "롯데건설 – 원주10BL 공동주택 옥상 조형물",
          "롯데건설 – 금천 롯데캐슬 골드파크 3차 옥상 조형물",
          "롯데건설 – 흑석동 8구역 옥상 조형물",
          "롯데건설 – 목동 제 1구역 옥상 조형물",
          "롯데건설 – 전농 11구역 옥상 조형물",
          "롯데건설 – 화성 반월동 아파트 옥상 조형물",
          "롯데건설 – 창원 합성동 아파트 옥상 조형물",
          "롯데건설 – 안산 고잔 아파트 옥상 조형물",
          "롯데건설 – 의정부 직동 1, 2단지 옥상 조형물",
          "태영건설 – 연산 4구역 아파트 사업 옥상 조형물",
          "포스코건설 – 송도국제업무단지 F16-1블록 옥상 조형물",
        ],
      },
      {
        title: "2016",
        contents: [
          "대우건설- 동탄2신도시 푸르지오 옥상 조형물",
          "대우건설 – 김포 한강 신도시 3차 옥상 조형물",
          "대우건설 – 광명역 주상복합 옥상 조형물",
          "대우건설 – 창원마린 푸르지오 옥상 조형물",
          "대우건설 – 천안 불당 지웰 푸르지오 1, 2단지 옥상 조형물",
          "대우건설 – 기흥역 지웰 푸르지오 옥상 조형물",
          "대우건설 – 구리 갈매 푸르지오 옥상 조형물",
          "대우건설 – 목감 푸르지오 옥상 조형물",
          "대우건설 – 창원 센텀 푸르지오 옥상 조형물",
          "대우건설 – 서창 센텀 푸르지오 옥상 조형물",
          "대우조선해양 – 진주 평거 엘크루 옥상 조형물",
          "동원개발 – 부산 센텀 비스타 동원2차 옥상 조형물",
          "롯데건설 – 아산 모종 2차 옥상 조형물",
          "포스코건설 – 홍은 12구역 주택재개발 정비 옥상 조형물",
          "포스코건설 – 송도 F13-1, 14, 15 블록 옥상 조형물",
          "한성건설 – 서산 동문 한성필하우스 옥상 조형물",
        ],
      },
      {
        title: "2015",
        contents: [
          "대우건설 – 평택비전 푸르지오 옥상 조형물",
          "대우건설 – 양산 신도시 푸르지오 옥상 조형물",
          "대우건설 – 하남미사 강변2차 푸르지오 옥상 조형물",
          "롯데건설 – 양산 주전동 롯데캐슬 옥상 조형물",
          "중흥건설 – 나주 빛가람 B-10 블록 에스클래스 옥상 조형물",
          "중흥건설 – 창원지은3지구 B-1블럭 에스클래스 옥상 조형물",
          "중흥건설 – 세종시 M4, M6 블록 에스클래스 옥상 조형물",
          "중흥건설 – 완주혁신도시 C-3블럭 에스클래스 옥상 조형물",
        ],
      },
      {
        title: "2014",
        contents: [
          "남양개발 – 통영 정량동 동원 로얄듀크 옥상 조형물",
          "대우건설 – 천안 불당 지웰 푸르지오 옥상 조형물",
          "대우건설 – 미사 푸르지오 옥상 조형물",
          "대우건설 – 북아현 푸르지오 옥상 조형물",
          "대우건설 – 남양주 별내 푸르지오 옥상 조형물",
          "대우건설 – 안산 레이크타운 푸르지오 옥상 조형물",
          "대우건설 – 아산 배방2차 푸르지오 옥상 조형물",
          "대우건설 – 의정부 민락 푸르지오 옥상 조형물",
          "대우조선해양 – 거제 엘크루 랜드마크 옥상 조형물",
          "동문건설 – 백양산 동문굿모닝힐 옥상 조형물",
          "롯데건설 – 대구 수성 롯데캐슬 옥상 조형물",
          "롯데건설 – 서대신동 아파트 재건축 옥상 조형물 ",
          "중흥건설 – 세종시 M1 블럭외 4개현장 옥상 조형물",
        ],
      },
      {
        title: "2013",
        contents: [
          "극동건설 – 웅진 스타클래스 안동 옥상 조형물",
          "극동건설 – 대구 남산동 아파트 옥상 조형물",
          "대우건설 – 시흥 6차 1단지 푸르지오 옥상 조형물",
          "대우건설 – 서산 예천 푸르지오 옥상 조형물",
          "롯데건설 – 다대3차 주공아파트 재건축 옥상 조형물",
          "롯데건설 – 다대 2차 주공아파트 재건축 옥상 조형물",
          "모아종합건설 – 세종시 M2블럭 모아미래도 아파트 옥상 조형물",
          "중흥건설 – 광주 효천2지구 S1블럭 옥상 조형물",
          "중흥건설 – 광주 하남상업지역 A1블럭 옥상 조형물",
          "중흥건설 – 세종 1-1, 1-2, 1-3 옥상 조형물 ",
          "중흥건설 – 신대배후단지 B-2-2 블록 옥상 조형물",
        ],
      },
    ],
  },
};
