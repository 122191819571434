import React, {Fragment} from "react";
import classnames from "classnames";
import {homeSection} from "src/constants/routes";
import p from "assets/icon/project.png";
import m from "assets/icon/money.png";
import o from "assets/icon/output.png";
import f from "assets/icon/factory.png";
import style from "./HomeProject.scss";
import Title from "./Title";

const data = [
  {name: "매출액", desc: [{number: "2,374", unit: "억"}], icon: m},
  {
    name: "시공능력 평가액",
    desc: [
      {number: "1,523", unit: "억", etc: "(철근콘크리트 공사업)"},
      {number: "976", unit: "억", etc: "(금속창호지붕건조-지붕판금건조)"},
    ],
    icon: p,
  },
  {name: "연간 생산량", desc: [{number: "290,000", unit: "m³"}], icon: o},
  {name: "공장수", desc: [{number: "5", unit: "개"}], icon: f},
];

const HomeProject = () => (
  <div className={style.container} id={homeSection.Project}>
    <Title title="회사 소개" />
    <div className={style.contents}>
      업계 최대 매출, 업계 최대 프로젝트를 자랑합니다
      <br />
    </div>
    <ul className={classnames("container", style.statistics)}>
      {data.map(({name, desc, icon}) => (
        <li key={name}>
          <img src={icon} alt={`${name} 아이콘`} width="100px" />
          <br />
          <br />
          <h4>{name}</h4>
          <div>
            {desc.map(({number, unit, etc}) => (
              <>
                <h2 style={{display: "inline-block"}}>{number}</h2>
                {unit && (
                  <span>
                    {unit}
                  </span>
                )}
                {etc && (
                  <>
                    <br />
                    <span>
                      {etc}
                    </span>
                  </>
                )}
              </>
            ))}
          </div>
        </li>
      ))}
    </ul>
    <span style={{fontSize: "0.8em"}}>2022년도 기준</span>
  </div>
);

export default HomeProject;
