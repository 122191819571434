export const mainRoutes = {
  Home: "/",
  Introduction: "/introduction",
  Contact: "/contact",
};

export const introductionParams = {
  Building: "",
  Parking: "parking",
  Etc: "etc",
};
export const introductionRoutes = Object.entries(introductionParams).reduce(
  (result, [key, value]) => {
    result[key] = value ? [mainRoutes.Introduction, value].join("/") : mainRoutes.Introduction;

    return result;
  },
  {},
);

export const homeSection = {
  Business: "business",
  Project: "project",
  Factory: "factory",
  Contact: "contact",
};

export const introductionNavData = [
  {text: "대형건축물", to: introductionRoutes.Building},
  {text: "지하주차장", to: introductionRoutes.Parking},
  {text: "기타", to: introductionRoutes.Etc},
];

export const url = [mainRoutes.Home, mainRoutes.Contact, ...Object.values(introductionRoutes)];
