import React, {useEffect} from "react";
import {renderRoutes} from "react-router-config";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import "./_bootswatch.scss";
import "./global.scss";
import TopNav from "./components/TopNav";
import Footer from "./components/Footer";
import routes from "./routes";
import {initLog} from "./utils/log";

const path = "kec";

const App = () => {
  useEffect(() => {
    initLog();
  }, []);

  return (
    <Router
      basename={typeof window !== "undefined" && location.pathname.includes(path) ? path : ""}
    >
      <TopNav />
      <Switch>{renderRoutes(routes)}</Switch>
      <Footer />
    </Router>
  );
};

export default App;
