import Home from "src/pages/Home";
import Introduction from "src/pages/Introduction";
import Contact from "src/pages/Contact";
import {mainRoutes} from "./constants/routes";
import RedirectHome from "./pages/RedirectHome";

export const pageRoutes = [
  {
    name: "HOME",
    path: mainRoutes.Home,
    exact: true,
    component: Home,
  },
  {
    name: "Contact",
    path: mainRoutes.Contact,
    exact: true,
    component: Contact,
  },
  {
    name: "Introduction",
    path: [mainRoutes.Introduction, `${mainRoutes.Introduction}/:project`],
    exact: true,
    component: Introduction,
  },
];

const routes = [
  ...pageRoutes,
  {
    name: "Redirect",
    path: "*",
    component: RedirectHome,
  },
];

export default routes;
